h1, h3 {
  margin: auto;
  padding: 0px 10px 5px 20px;
}

.heading {
  padding-top: 10px;
}

h3 {
  color: #D0CD94;
}

.homebtn {
  position: absolute;
  right: 0.5rem;
}
